<template>
  <el-card shadow="never">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row class="row">
          <TableTitleComponent title="个人每日产能"/>
          <UserDayCapacity/>
        </el-row>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import UserDayCapacity from "@/views/userReport/UserDayCapacity";
import TableTitleComponent from "@/components/TableTitleComponent";

export default {
  name: "ExportUserReport",
  components: {TableTitleComponent, UserDayCapacity}
}
</script>

<style scoped>
.row {
  padding-bottom: 20px;
}
</style>